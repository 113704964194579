exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx?export=default" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-content-index-tsx": () => import("./../../../src/pages/content/index.tsx?export=default" /* webpackChunkName: "component---src-pages-content-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx?export=default" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-subscribe-index-tsx": () => import("./../../../src/pages/subscribe/index.tsx?export=default" /* webpackChunkName: "component---src-pages-subscribe-index-tsx" */),
  "component---src-pages-talks-quick-apps-index-tsx": () => import("./../../../src/pages/talks/quick-apps/index.tsx?export=default" /* webpackChunkName: "component---src-pages-talks-quick-apps-index-tsx" */),
  "component---src-pages-talks-react-concurrency-index-tsx": () => import("./../../../src/pages/talks/react-concurrency/index.tsx?export=default" /* webpackChunkName: "component---src-pages-talks-react-concurrency-index-tsx" */),
  "component---src-pages-talks-web-perf-101-index-tsx": () => import("./../../../src/pages/talks/web-perf-101/index.tsx?export=default" /* webpackChunkName: "component---src-pages-talks-web-perf-101-index-tsx" */),
  "component---src-pages-workshops-index-tsx": () => import("./../../../src/pages/workshops/index.tsx?export=default" /* webpackChunkName: "component---src-pages-workshops-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx?export=default" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-legal-index-tsx": () => import("./../../../src/templates/legal/index.tsx?export=default" /* webpackChunkName: "component---src-templates-legal-index-tsx" */)
}



exports.head = {
  "component---src-pages-content-index-tsx": () => import("./../../../src/pages/content/index.tsx?export=head" /* webpackChunkName: "component---src-pages-content-index-tsxhead" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx?export=head" /* webpackChunkName: "component---src-pages-index-tsxhead" */),
  "component---src-pages-subscribe-index-tsx": () => import("./../../../src/pages/subscribe/index.tsx?export=head" /* webpackChunkName: "component---src-pages-subscribe-index-tsxhead" */),
  "component---src-pages-talks-quick-apps-index-tsx": () => import("./../../../src/pages/talks/quick-apps/index.tsx?export=head" /* webpackChunkName: "component---src-pages-talks-quick-apps-index-tsxhead" */),
  "component---src-pages-talks-react-concurrency-index-tsx": () => import("./../../../src/pages/talks/react-concurrency/index.tsx?export=head" /* webpackChunkName: "component---src-pages-talks-react-concurrency-index-tsxhead" */),
  "component---src-pages-talks-web-perf-101-index-tsx": () => import("./../../../src/pages/talks/web-perf-101/index.tsx?export=head" /* webpackChunkName: "component---src-pages-talks-web-perf-101-index-tsxhead" */),
  "component---src-pages-workshops-index-tsx": () => import("./../../../src/pages/workshops/index.tsx?export=head" /* webpackChunkName: "component---src-pages-workshops-index-tsxhead" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx?export=head" /* webpackChunkName: "component---src-templates-blog-index-tsxhead" */),
  "component---src-templates-legal-index-tsx": () => import("./../../../src/templates/legal/index.tsx?export=head" /* webpackChunkName: "component---src-templates-legal-index-tsxhead" */)
}

